<template>
  <v-card class="my-4" elevation="4">
    <v-card-title><v-icon class="mr-2">mdi-flag</v-icon> Bandeiras</v-card-title>
    <v-card-text class="mx-2 py-0">
      <tooltip-switch
        class-props="mt-1"
        :editable="true"
        :right="true"
        :value.sync="active"
        label="Iniciar app com seleção de bandeiras"
        :tip="appFlagTip"
        @update:value="value => (this.active = value)"
      />
    </v-card-text>
    <v-card-actions class="pl-0">
      <v-spacer />
      <v-btn color="primary" :disabled="loading" @click="handleEnableOrDisableRetailerFlags"><v-icon class="mr-1">sync</v-icon> Atualizar Bandeiras</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { QUERY_CONFIGURATION } from '@/modules/accounts/graphql'
import { MUTATION_UPDATE_RETAILER_FLAG_CONFIG } from '@/modules/accounts/graphql/edit/retailerFlags'
export default {
  components: {
    TooltipSwitch: () => import('@/components/base/TooltipSwitch')
  },
  data: () => ({
    active: false,
    accountId: null,
    appFlagTip: 'Controla o fluxo de seleção de Bandeiras do aplicativo.'
  }),
  apollo: {
    getConfiguration: {
      query: QUERY_CONFIGURATION,
      fetchPolicy: 'network-only',
      variables() {
        return { account_id: this.accountId }
      },
      context() {
        return {
          uri: this.$microservicesUrls['accounts'],
          headers: {
            authorization: localStorage.getItem('session_id')
          }
        }
      },
      result({ data }) {
        this.active = data.configuration.retailer_flag_config.start_with_selector
      }
    }
  },
  mounted() {
    this.accountId = this.$route.params.id
  },
  methods: {
    refresh() {
      this.$apollo.queries.getConfiguration.refresh()
    },
    async handleEnableOrDisableRetailerFlags() {
      const updateRetailerFlagVariables = {
        accountId: this.accountId,
        input: {
          startWithRetailerFlagSelector: this.active
        }
      }
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_RETAILER_FLAG_CONFIG,
          variables: updateRetailerFlagVariables,
          context: {
            headers: {
              authorization: localStorage.getItem('session_id')
            }
          }
        })
        this.$snackbar({ message: 'Bandeiras habilitadas com sucesso', snackbarColor: '#2E7D32' })
      } catch (err) {
        this.$snackbar({ message: 'Falha ao habilitar as Bandeiras no aplicativo', snackbarColor: '#F44336' })
      }
    }
  }
}
</script>
